<template>
  <div class="pdf">
    <div class="opt fa">
      <div class="opt_btn f1 faj" @click.stop="prePage">上一页</div>
      <div>{{ pageNum }}/{{ pageTotalNum }}</div>
      <div class="opt_btn f1 faj" @click.stop="nextPage">下一页</div>
      <!-- <div class="btn-def" @click.stop="clock">顺时针</div>
      <div class="btn-def" @click.stop="counterClock">逆时针</div>
      <div class="btn-def" @click.stop="pdfPrintAll">全部打印</div>
      <div class="btn-def" @click.stop="pdfPrint">部分打印</div> -->
    </div>
    
    <!-- <div>进度：{{ loadedRatio }}</div>
    <div>页面加载成功: {{ curPageNum }}</div> -->
    <pdf
      ref="pdf"
      :src="pdfUrl"
      :page="pageNum"
      :rotate="pageRotate"
      @password="password"
      @progress="loadedRatio = $event"
      @page-loaded="pageLoaded($event)"
      @num-pages="pageTotalNum = $event"
      @error="pdfError($event)"
      @link-clicked="page = $event"
    >
    </pdf>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  name: "Pdf",
  components: {
    pdf,
  },
  data() {
    return {
      pdfUrl: "",
      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
    };
  },
  mounted() {
    this.pdfUrl = "http://shop.wesky.online/" + this.$route.query.src;
  },
  methods: {
    prePage() {
      var p = this.pageNum;
      p = p > 1 ? p - 1 : this.pageTotalNum;
      this.pageNum = p;
    },
    nextPage() {
      var p = this.pageNum;
      p = p < this.pageTotalNum ? p + 1 : 1;
      this.pageNum = p;
    },
    clock() {
      this.pageRotate += 90;
    },
    counterClock() {
      this.pageRotate -= 90;
    },
    password(updatePassword, reason) {
      updatePassword(prompt('password is "123456"'));
      console.log("...reason...");
      console.log(reason);
      console.log("...reason...");
    },
    pageLoaded(e) {
      this.curPageNum = e;
    },
    pdfError(error) {
      console.error(error);
    },
    pdfPrintAll() {
      this.$refs.pdf.print();
    },
    pdfPrint() {
      this.$refs.pdf.print(100, [1, 2]);
    },
  },
};
</script>
<style scoped>
.opt {
  width: 100%;
  padding: 0 0.3rem;
  position: fixed;
  box-sizing: border-box;
  bottom: 0.1rem;
  font-size: 0.3rem;
}
.opt_btn {
  margin: 0 0.1rem;
  height: 0.6rem;
  color: #333;
  background: #f0f0f0;
  border-radius: 4px;
}
</style>